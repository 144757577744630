var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portal',{attrs:{"to":"breadcrumb"}},[_c('Breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'team'},"tag":"router-link"}},[_vm._v(" Team ")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'roles'},"tag":"router-link"}},[_vm._v(" Roles ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[(this.$route.params.roleId)?_c('span',[_vm._v(_vm._s(_vm.roleName))]):_c('span',[_vm._v("Create new role")])])],1)],1),_c('card',{attrs:{"external-card":"material-card-content","internal-card":"material-card material-card-content","url-help":""}},[_c('b-tabs',{attrs:{"slot":"body"},slot:"body",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"Settings"}},[_c('b-field',{staticClass:"force-parameters-right",attrs:{"label":"Role name"}},[_c('b-input',{attrs:{"placeholder":"Give your role a descriptive name","disabled":_vm.isOwner || !_vm.canEdit,"type":"text"},model:{value:(_vm.roleName),callback:function ($$v) {_vm.roleName=$$v},expression:"roleName"}})],1),_c('hr'),_vm._l((_vm.sections),function(section,parentIndex){return _c('div',{key:parentIndex},[(section.value.requestedCapabilities ?
              _vm.validateIfHasCapability(section.value.requestedCapabilities) : true)?_c('div',[_c('p',{staticClass:"field mt-5"},[_c('b',[_vm._v(_vm._s(section.value.name))])]),_vm._l((_vm.permissions.filter((permission) =>
                permission.value.section === section.value.id
              )),function(permission,childIndex){return _c('div',{key:childIndex,staticClass:"mb-1"},[_c('div',[_c('b-switch',{style:(permission.value.isParent && childIndex !== 0
                    ? 'margin: 1em 0em 0em 0em;': 'margin: 0em;'),attrs:{"disabled":!permission.value.canSwitch || _vm.isOwner || !_vm.canEdit},on:{"input":function($event){!permission.value.parent ?
                    _vm.updateChildSettings(permission) : null}},model:{value:(permission.value.enabled),callback:function ($$v) {_vm.$set(permission.value, "enabled", $$v)},expression:"permission.value.enabled"}},[_vm._v(" "+_vm._s(permission.value.name)+" "),(permission.value.tooltip)?_c('b-tooltip',{attrs:{"label":permission.value.tooltip,"position":"is-right","multilined":"","type":"is-dark","size":"is-medium"}},[_c('b-icon',{attrs:{"icon":"help-circle","size":"is-small"}})],1):_vm._e()],1),(permission.value.id === _vm.rolesPermissions.USER_IDLE_TIME.value.id
                    && permission.value.enabled)?_c('b-dropdown',{staticClass:"inner-element mt-2",attrs:{"disabled":!permission.value.canSwitch || _vm.isOwner || !_vm.canEdit,"aria-role":"list","position":"is-bottom-right","scrollable":""},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{attrs:{"label":_vm.maximumIdleTime ? `After ${_vm.getFrequencyName(_vm.frequenciesArray.find(
                        x => x.minutes === _vm.maximumIdleTime))} of inactivity` : 'Days',"icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true),model:{value:(_vm.maximumIdleTime),callback:function ($$v) {_vm.maximumIdleTime=$$v},expression:"maximumIdleTime"}},_vm._l((_vm.filterFrequencies),function(frequency){return _c('b-dropdown-item',{key:frequency.id,attrs:{"aria-role":"listitem","value":frequency.minutes}},[_vm._v(" After "+_vm._s(_vm.getFrequencyName(frequency))+" of inactivity ")])}),1):_vm._e()],1)])}),_c('hr')],2):_vm._e()])})],2),(!_vm.isOwner)?_c('b-tab-item',{attrs:{"label":"Modify members"}},[_c('div',[_c('b-table',{ref:"userListTable",staticClass:"userList",attrs:{"data":_vm.usersResult,"mobile-cards":false,"striped":true,"paginated":_vm.totalData > _vm.limitUsers,"per-page":_vm.limitUsers,"loading":_vm.usersResult.length === 0,"current-page":_vm.currentPage,"show-detail-icon":false,"default-sort":_vm.sortField,"default-sort-direction":_vm.sortDirection,"hoverable":"","scrollable":"","backend-pagination":"","backend-sorting":"","total":_vm.totalData},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"page-change":_vm.changePagination,"sort":_vm.sortTable}},[_c('b-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-checkbox',{on:{"input":(value) => _vm.onCheckAll(value)}})]},proxy:true},{key:"default",fn:function(props){return [_c('b-checkbox',{attrs:{"value":_vm.checkedRows.map((x)=> x.id).includes(props.row.id)},on:{"input":(value) => _vm.onCheckRow(value, props.row)}})]}}],null,false,3304174998)}),_vm._l(([{
                field: 'email',
                label: 'Email',
              }, {
                field: 'name',
                label: 'Name',
              }, {
                field: 'lastName',
                label: 'Last Name',
              }]),function(column,index){return _c('b-table-column',{key:index,attrs:{"label":column.label,"field":column.field,"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row[column.field])+" ")]}}],null,true)})})],2)],1)]):_vm._e(),_c('b-tab-item',{attrs:{"label":`Members ${_vm.checkedRows.length > 0 ?
          '(' + _vm.checkedRows.length + ')' : ''}`}},[_c('b-table',{attrs:{"data":_vm.checkedRows,"mobile-cards":false,"paginated":_vm.checkedRows.length > _vm.limitUsers,"per-page":_vm.limitUsers,"columns":[{
            field: 'email',
            label: 'Email',
          }, {
            field: 'name',
            label: 'Name',
          }, {
            field: 'lastName',
            label: 'Last Name',
          }],"hoverable":""}},[_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v("There are no members in this role")])])])])],2)],1)],1),(!_vm.isOwner)?_c('b-button',{staticClass:"button is-primary force-right",class:{'is-loading': _vm.isLoading },attrs:{"slot":"body"},on:{"click":_vm.deployConfiguration},slot:"body"},[_vm._v(" Save ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }