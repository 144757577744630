<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item
          :to="{ name: 'team'}"
          tag="router-link"
        >
          Team
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :to="{ name: 'roles'}"
          tag="router-link"
        >
          Roles
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          <span v-if="this.$route.params.roleId">{{ roleName }}</span>
          <span v-else>Create new role</span>
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-content"
      internal-card="material-card material-card-content"
      url-help=""
    >
      <b-tabs
        slot="body"
        v-model="activeTab"
      >
        <b-tab-item
          label="Settings"
        >
          <b-field
            label="Role name"
            class="force-parameters-right"
          >
            <b-input
              v-model="roleName"
              placeholder="Give your role a descriptive name"
              :disabled="isOwner || !canEdit"
              type="text"
            />
          </b-field>
          <hr>

          <div
            v-for="(section, parentIndex) in sections"
            :key="parentIndex"
          >
            <div
              v-if="section.value.requestedCapabilities ?
                validateIfHasCapability(section.value.requestedCapabilities) : true"
            >
              <p
                class="field mt-5"
              >
                <b>{{ section.value.name }}</b>
              </p>
              <div
                v-for="(permission, childIndex) in permissions.filter((permission) =>
                  permission.value.section === section.value.id
                )"
                :key="childIndex"
                class="mb-1"
              >
                <div>
                  <b-switch
                    v-model="permission.value.enabled"
                    :disabled="!permission.value.canSwitch || isOwner || !canEdit"
                    :style="permission.value.isParent && childIndex !== 0
                      ? 'margin: 1em 0em 0em 0em;': 'margin: 0em;'"
                    @input="!permission.value.parent ?
                      updateChildSettings(permission) : null"
                  >
                    {{ permission.value.name }}
                    <b-tooltip
                      v-if="permission.value.tooltip"
                      :label="permission.value.tooltip"
                      position="is-right"
                      multilined
                      type="is-dark"
                      size="is-medium"
                    >
                      <b-icon
                        icon="help-circle"
                        size="is-small"
                      />
                    </b-tooltip>
                  </b-switch>
                  <b-dropdown
                    v-if="permission.value.id === rolesPermissions.USER_IDLE_TIME.value.id
                      && permission.value.enabled"
                    v-model="maximumIdleTime"
                    :disabled="!permission.value.canSwitch || isOwner || !canEdit"
                    aria-role="list"
                    class="inner-element mt-2"
                    position="is-bottom-right"
                    scrollable
                  >
                    <template #trigger="{ active }">
                      <b-button
                        :label="maximumIdleTime ? `After ${getFrequencyName(frequenciesArray.find(
                          x => x.minutes === maximumIdleTime))} of inactivity` : 'Days'"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                      />
                    </template>
                    <b-dropdown-item
                      v-for="frequency in filterFrequencies"
                      :key="frequency.id"
                      aria-role="listitem"
                      :value="frequency.minutes"
                    >
                      After {{ getFrequencyName(frequency) }} of inactivity
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item
          v-if="!isOwner"
          label="Modify members"
        >
          <div>
            <b-table
              ref="userListTable"
              :data="usersResult"
              :mobile-cards="false"
              :striped="true"
              :paginated="totalData > limitUsers"
              :per-page="limitUsers"
              :loading="usersResult.length === 0"
              :current-page.sync="currentPage"
              :show-detail-icon="false"
              :default-sort="sortField"
              :default-sort-direction="sortDirection"
              hoverable
              scrollable
              class="userList"
              backend-pagination
              backend-sorting
              :total="totalData"
              @page-change="changePagination"
              @sort="sortTable"
            >
              <b-table-column>
                <template v-slot:header>
                  <b-checkbox
                    @input="(value) => onCheckAll(value)"
                  />
                </template>
                <template v-slot="props">
                  <b-checkbox
                    :value="checkedRows.map((x)=> x.id).includes(props.row.id)"
                    @input="(value) => onCheckRow(value, props.row)"
                  />
                </template>
              </b-table-column>
              <b-table-column
                v-for="(column, index) in [{
                  field: 'email',
                  label: 'Email',
                }, {
                  field: 'name',
                  label: 'Name',
                }, {
                  field: 'lastName',
                  label: 'Last Name',
                }]"
                :key="index"
                :label="column.label"
                :field="column.field"
                sortable
              >
                <template v-slot="props">
                  {{ props.row[column.field] }}
                </template>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
        <b-tab-item
          :label="`Members ${checkedRows.length > 0 ?
            '(' + checkedRows.length + ')' : ''}`"
        >
          <b-table
            :data="checkedRows"
            :mobile-cards="false"
            :paginated="checkedRows.length > limitUsers"
            :per-page="limitUsers"
            :columns="[{
              field: 'email',
              label: 'Email',
            }, {
              field: 'name',
              label: 'Name',
            }, {
              field: 'lastName',
              label: 'Last Name',
            }]"
            hoverable
          >
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>There are no members in this role</p>
                </div>
              </section>
            </template>
          </b-table>
        </b-tab-item>
      </b-tabs>
      <b-button
        v-if="!isOwner"
        slot="body"
        :class="{'is-loading': isLoading }"
        class="button is-primary force-right"
        @click="deployConfiguration"
      >
        Save
      </b-button>
    </card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import card from '@/components/cross/Card.vue';
import toastMessage from '@/helpers/toastMessage';
import parseEnum from '@/helpers/parseEnums';
import rolesEnum from '../../../cross/src/roles.enum';
import CompanyMixin from '../mixins/company';
import RoleMixin from '../mixins/roles';
import vtulEnums from '../../../cross/index';

const { frequencies, views } = vtulEnums.enum.settings;

export default {
  name: 'ManageRolesPermissions',
  metaInfo() {
    return {
      title: this.$route.params.roleId ? 'Edit Role' : 'Create Role',
    };
  },
  components: {
    card,
  },
  mixins: [CompanyMixin, RoleMixin],
  data() {
    return {
      limitUsers: 20,
      sortDirection: 'asc',
      sortField: 'name',
      skipUsers: 0,
      currentPage: 1,
      activeTab: 0,
      settings: [],
      sections: [],
      permissions: [],
      checkedRows: [],
      uncheckedRows: [],
      roleName: '',
      createdRole: null,
      isLoading: false,
      isOwner: false,
      canEdit: true,
      maximumIdleTime: frequencies.ONE_WEEK.value.minutes,
      frequenciesArray: parseEnum.enumToArray(frequencies),
      currentView: views.ROLES.value,
      frequenciesEnum: frequencies,
      rolesPermissions: rolesEnum.permissions,
    };
  },
  computed: {
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    ...mapGetters('user', { findUsersInStore: 'find' }),
    currentPaginationQuery() {
      return {
        $limit: this.limitUsers,
        $skip: this.skipDevices,
      };
    },
    usersResult() {
      const memberships = this.findMembershipsInStore({
        query: {
          companyId: this.currentCompany.id,
          isOwner: false,
          $limit: this.limitUsers,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
        },
      });

      const users = this.findUsersInStore({
        query: {
          id: {
            $in: memberships.data.map((membership) => membership.userId),
          },
          $limit: this.limitUsers,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
        },
      });
      return users.data.filter(
        (user) => user.id !== this.$store.getters['auth/user'].id && !user.isSupportAccount,
      );
    },
    totalData() {
      if (!this.usersLatestQuery || !this.usersPaginationData.default
          || !this.usersPaginationData.default[this.usersLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.usersLatestQuery;
      const { total } = this.usersPaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitUsers);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }
      return total;
    },
    filterFrequencies() {
      return this.frequenciesArray.filter(
        (x) => x.minutes >= this.frequenciesEnum.TEN_MINUTES.value.minutes
        && x.minutes <= this.frequenciesEnum.ONE_WEEK.value.minutes
        && x.views.includes(this.currentView),
      );
    },
  },
  created() {
    this.syncData();
  },
  methods: {
    ...mapActions('roles', { findRole: 'find', updateRolePermissions: 'patch', createRole: 'create' }),
    ...mapActions('memberships', { findMemberships: 'find', updateMemberships: 'patch' }),
    ...mapActions('user', { findUsers: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    async findAllRelatedUsers() {
      let memberships = await this.fetchMembershipsFromApi();
      if (this.isOwner) {
        memberships = memberships.filter(
          (user) => user.isOwner,
        );
      }
      let users = await this.fetchUsersFromApi(memberships.map((m) => m.userId));
      if (!this.$route.params.roleId) {
        return [];
      }
      let currentRoleMemberships;
      if (!this.isOwner) {
        currentRoleMemberships = memberships.filter(
          (membership) => membership.roleId === this.$route.params.roleId,
        );
        users = users.filter(
          (user) => currentRoleMemberships.some(
            (membership) => membership.userId === user.id && !membership.isOwner,
          ),
        );
      }
      users = users.filter(
        (user) => !user.isSupportAccount,
      );
      return users;
    },
    async fetchMembershipsFromApi() {
      let membershipsList = [];
      let membershipsSkip = 0;
      let membershipsTotal;
      do {
        // eslint-disable-next-line no-await-in-loop
        const membershipsAnswer = await this.findMemberships({
          query: {
            companyId: this.currentCompany.id,
            $limit: 50,
            $skip: membershipsSkip,
          },
        });
        membershipsList = membershipsList.concat(membershipsAnswer.data);
        membershipsSkip += membershipsAnswer.data.length;
        membershipsTotal = membershipsAnswer.total;
      } while (membershipsSkip < membershipsTotal);
      return membershipsList;
    },
    async fetchUsersFromApi(membershipsIds) {
      let usersList = [];
      let usersSkip = 0;
      let usersTotal;
      do {
        // eslint-disable-next-line no-await-in-loop
        const usersAnswer = await this.findUsers({
          query: {
            enabledInDatabase: true,
            id: { $in: membershipsIds },
            $limit: 50,
            $skip: usersSkip,
          },
        });
        usersList = usersList.concat(usersAnswer.data);
        usersSkip += usersAnswer.data.length;
        usersTotal = usersAnswer.total;
      } while (usersSkip < usersTotal);
      return usersList;
    },
    async syncData() {
      try {
        if (this.$route.params.roleId) {
          const role = await this.findRole({
            query: {
              $limit: 1,
              id: this.$route.params.roleId,
              companyId: this.currentCompany.id,
            },
          });
          if (role.data && role.data.length <= 0) {
            this.isOwner = true;
          } else if (this.currentRole.id === role.data[0].id && !this.currentUserIsOwner) {
            this.canEdit = false;
          }
          this.roleName = this.isOwner ? 'Owner' : role.data[0].name;
          this.settings = rolesEnum.permissions.enums.filter(
            (permission) => this.validateIfHasCapability(permission.value.requestedCapabilities),
          );
          if (!this.$isBgCloud) {
            this.settings = this.settings.filter((setting) => !setting.value.hideOnPremise);
          }
          this.sections = this.settings.filter((setting) => !setting.value.section);
          this.sections.sort((a, b) => a.value.position - b.value.position);
          const parentPermissions = this.settings.filter((setting) => setting.value.section
            && !setting.value.parent);
          parentPermissions.forEach((x) => {
            const permission = x;
            permission.value.isParent = true;
            this.permissions.push(permission);
            const child = this.settings.filter((setting) => setting.value.parent === x.value.id);
            this.permissions = [
              ...this.permissions,
              ...child,
            ];
          });
          this.permissions.forEach((setting, index) => {
            const { dbValue } = setting.value;
            if (this.isOwner) {
              this.permissions[index].value.enabled = setting.value.id !== rolesEnum.permissions
                .USER_IDLE_TIME.value.id;
            } else {
              this.permissions[index].value.enabled = setting.value.id === rolesEnum.permissions
                .USER_IDLE_TIME.value.id ? role.data[0].maxIdleTime.enabled
                : role.data[0].permissions.includes(dbValue);
            }
          });
          this.maximumIdleTime = this.isOwner ? this.maximumIdleTime
            : role.data[0].maxIdleTime.frequency;
        } else {
          this.settings = rolesEnum.permissions.enums.filter(
            (permission) => this.validateIfHasCapability(permission.value.requestedCapabilities),
          );
          if (!this.$isBgCloud) {
            this.settings = this.settings.filter((setting) => !setting.value.hideOnPremise);
          }
          this.sections = this.settings.filter((setting) => !setting.value.section);
          this.permissions = this.settings.filter((setting) => setting.value.section);
        }
        const users = await this.findAllRelatedUsers();
        this.checkedRows = users;
      } catch (error) {
        toastMessage.showError('We can\'t get the user permissions, try again.');
      }
    },
    async deployConfiguration() {
      this.isLoading = true;
      const savePermissionsAnswer = await this.savePermissions();
      const modifyMembershipsAnswer = await this.modifyMemberships();
      this.isLoading = false;
      if (savePermissionsAnswer && modifyMembershipsAnswer) {
        toastMessage.showSuccess('Role updated successfully.');
      }
    },
    async savePermissions() {
      const filteredChildSettings = this.permissions.filter((setting) => setting.value.enabled);
      const permissions = filteredChildSettings.map((setting) => setting.value.dbValue);
      try {
        if (this.$route.params.roleId) {
          await this.updateRolePermissions([this.$route.params.roleId, {
            name: this.roleName,
            maxIdleTime: {
              enabled: permissions.includes(rolesEnum.permissions.USER_IDLE_TIME.value.dbValue),
              frequency: this.maximumIdleTime,
            },
            permissions: permissions.filter((permission) => permission !== rolesEnum
              .permissions.USER_IDLE_TIME.value.dbValue),
          }, {
            query: {
              companyId: this.currentCompany.id,
            },
          }]);
          this.uncheckedRows.forEach(async (role) => {
            const user = this.$store.getters['auth/user'];
            const log = {
              user: user.email,
              companyId: this.currentCompany.id,
              action: `${role.name}'s role changed to default, due to group removal.`,
            };
            await this.createAdminLog(log);
          });
          this.checkedRows.forEach(async (role) => {
            const user = this.$store.getters['auth/user'];
            const log = {
              user: user.email,
              companyId: this.currentCompany.id,
              action: `${role.name}'s role changed to ${this.roleName}.`,
            };
            await this.createAdminLog(log);
          });
        } else {
          this.createdRole = await this.createRole({
            name: this.roleName,
            permissions,
            companyId: this.currentCompany.id,
          });
        }
        return true;
      } catch (error) {
        toastMessage.showError(error);
        this.syncData();
        return false;
      }
    },
    async modifyMemberships() {
      try {
        await this.updateMemberships([null, {
          roleId: this.createdRole ? this.createdRole.id : this.$route.params.roleId,
        }, {
          query: {
            companyId: this.currentCompany.id,
            userId: {
              $in: this.checkedRows.map((x) => x.id),
            },
          },
        }]);
        if (this.uncheckedRows.length > 0) {
          const defaultRole = await this.findRole({
            query: {
              $limit: 1,
              isDefaultRole: true,
              companyId: this.currentCompany.id,
            },
          });
          await this.updateMemberships([null, {
            roleId: defaultRole.data[0].id,
          }, {
            query: {
              companyId: this.currentCompany.id,
              userId: {
                $in: this.uncheckedRows.map((x) => x.id),
              },
            },
          }]);
        }
        this.$router.push(`/${this.currentCompany.id}/roles`);
        return true;
      } catch (error) {
        toastMessage.showError(error);
        return false;
      }
    },
    updateChildSettings(parentSetting) {
      this.permissions.forEach((childSetting, index) => {
        if (childSetting.value.parent === parentSetting.value.id) {
          this.permissions[index].value.enabled = !!parentSetting.value.enabled;
          this.permissions[index].value.canSwitch = !!parentSetting.value.enabled;
        }
      });
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipUsers = (this.currentPage - 1) * this.limitUsers;
    },
    sortTable(field, direction) {
      this.sortField = field;
      this.sortDirection = direction;
    },
    onCheckRow(value, user) {
      if (value) {
        this.checkedRows.push(user);
        const index = this.uncheckedRows.findIndex((x) => x.id === user.id);
        if (index !== -1) {
          this.uncheckedRows.splice(index, 1);
        }
      } else {
        this.uncheckedRows.push(user);
        const index = this.checkedRows.findIndex((x) => x.id === user.id);
        if (index !== -1) {
          this.checkedRows.splice(index, 1);
        }
      }
    },
    onCheckAll(value) {
      if (value) {
        this.checkedRows = this.usersResult;
        this.uncheckedRows = [];
      } else {
        this.checkedRows = [];
        this.uncheckedRows = this.usersResult;
      }
    },
    getRightFrequencyArticle(frequency) {
      let text = '';
      if (frequency === this.frequenciesEnum.ONE_HOUR.value.minutes
          || frequency === this.frequenciesEnum.TWENTY_FOUR_HOURS.value.minutes
          || frequency === this.frequenciesEnum.ONE_WEEK.value.minutes
          || frequency === this.frequenciesEnum.ONE_MONTH.value.minutes
          || frequency === this.frequenciesEnum.ONE_YEAR.value.minutes) {
        text = 'a';
      }
      return text;
    },
    getFrequencyName(frequency) {
      const article = this.getRightFrequencyArticle(frequency.minutes);
      return `${article} ${frequency.name}`;
    },
  },
};
</script>

<style scoped>
.card-form-container {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  max-width: 800px;
}

.card-content {
  padding: 1rem !important;
}

.inner-element {
  display: block !important;
  margin-left: 55px;
}
</style>
